.project-card {
    display: grid;
    grid-auto-flow: row;
    align-content:space-between;
    grid-gap: 10px;
    padding: 20px;
    border-radius: 5px;
    min-width: 260px;
    max-width: 620px;
    width: 80vw;
    min-height: 200px;
    background-color: rgb(255, 255, 255);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  ;
}

.title {
    font-weight: bold;
    font-size: larger;
}

.description {
    text-align: start;
    font-weight: 500;
    font-size: large;
}

.technologies-text {
    color: rgb(88, 88, 88);
}

.buttons {
    display: grid;
    grid-auto-flow: column;
    justify-items: center;
    justify-content: center;
    grid-gap: 10px;
}
body {
  margin: 0px;
}

.root {
  background-size: cover;
  text-align: center;
  display: grid;
  align-items: start;
  grid-gap: 20px;
}

.footer {
  display: grid;
  justify-items: center;
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
  grid-gap: 10px;
}

.personal-info {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 40px;
  height: 100vh;
  justify-items: center;
  align-items: center;
  align-content: center;
  background-color: rgb(255, 255, 255);
}

.intro-text {
  font-size: 50px;
  font-weight: 500;
  width: 70%;
  max-width: 1500px;
  align-self: start;
}

.contact {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  padding: 20px;
  border-radius: 20px;
  background-color: rgb(247, 247, 247);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.highlighted-text {
  color: rgb(54, 81, 148);
}

.avatar {
  height: 150px;
  border-radius: 50%;
}

.experience-current {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-gap: 20px;
}

.projects {
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  grid-gap: 20px;
}

@media screen and (min-width:690px) {
  .education {
    display: grid;
    grid-template-columns: 300px 300px;
    justify-content: center;
    grid-gap: 20px;
  }

  .experience-past {
    display: grid;
    grid-template-columns: 300px 300px;
    justify-content: center;
    grid-gap: 20px;
  }
}

@media screen and (max-width:690px) {
  .education {
    display: grid;
    grid-template-columns: 300px;
    justify-content: center;
    grid-gap: 20px;
  }

  .experience-past {
    display: grid;
    grid-template-columns: 300px;
    justify-content: center;
    grid-gap: 20px;
  }

  .intro-text {
    width: 90%;
    font-size: 34px;
    justify-self: center;
  }
}

.test-results {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-gap: 20px;
}

.links {
  display: flex;
  justify-content: center;
  gap: 20px;
}

a {
  color: black;
  text-decoration: none;
  font-size: large;
}

a:hover {
  text-decoration: underline;
}